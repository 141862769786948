import { type Telemed } from '../api/telemed'
import { type AccountModel } from '../models/Account/Account.model'

export function accountServiceFactory(telemed: Telemed) {
    return (account: AccountModel) => new AccountService(telemed, account)
}

export type TAccountServiceFactory = ReturnType<typeof accountServiceFactory>

export class AccountService {
    constructor(protected telemed: Telemed, protected account: AccountModel) {}
}
