import { type Observable } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'

import { INSTRUMENTATION } from '../constants/Measurements/Instrumentation'
import { NORMS } from '../constants/Measurements/Norms'
import { TYPES_MEASUREMENTS } from '../constants/Measurements/TypesMeasurements'
import { type IInstrumentation, type INorm, type ITypeMeasurements } from '../ui/Measurements/models/Measurements.model'

import { type AuthService } from './authService'

interface PermissionPages {
    page: string
    isNavigation: boolean
    accessIsAllowedEmptyUser: boolean
    name: string
    availableRole: string[]
}

interface Permissions {
    permissionPages: PermissionPages[]
}

interface AvailableRoles {
    currenRole: string
    availableRole: string[]
}

export interface Config {
    projectName: string
    permissions: Permissions
    availableInstrumentation: string[]
    availableTypeMeasurement: string[]
    availableNorm: string[]
    availableLimit: string[]
    urgentInspection: boolean
    availableRoles: AvailableRoles[]
    isHaveInvite: boolean
    isMapPatient: boolean
    haveChangeUser: boolean
    haveAddUser: boolean
    logo?: string
    haveDiagnosisSettings: boolean
    haveSaveSettingsLimit: boolean
    haveChangeSettingsLimit: boolean
    haveAddOrganization: boolean
    haveChangeOrganization: boolean
    haveViewOrganizationsMeasurement: boolean
    isServerTime: boolean
    isIntegration: boolean
    showStatisticsReports: boolean
    showParentOrg: boolean
    phoneLengthTen: boolean
    isAuthToken: boolean
    isShowGraphicsPatient: boolean
}

export function getConfig(): Observable<Config> {
    return ajax<Config>({ url: '/conf/config.json', responseType: 'json' }).pipe(map((resp) => resp.response))
}

export class ConfigStore {
    availableInstrumentation: IInstrumentation[] = []
    availableTypeMeasurement: ITypeMeasurements[] = []
    availableLimit: ITypeMeasurements[] = []
    availableNorm: INorm[] = []

    constructor(public config: Config, protected authService: AuthService) {
        this.availableInstrumentation = INSTRUMENTATION.filter((item) =>
            this.config.availableInstrumentation.includes(item.code)
        )
        this.availableTypeMeasurement = TYPES_MEASUREMENTS.filter((item) =>
            this.config.availableTypeMeasurement.includes(item.code)
        )
        this.availableLimit = TYPES_MEASUREMENTS.filter((item) => this.config.availableLimit.includes(item.code))
        this.availableNorm = NORMS.filter((item) => this.config.availableNorm.includes(item.code))
    }
}
