import { type Observable, forkJoin } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { asyncResolver } from '../../../shared/utils/async-component'

import { type Telemed } from '../../../shared/api/telemed'
import { AuthService, TelemedService } from '../../../shared/services'
import { type Config, ConfigStore, getConfig } from '../../../shared/services/config'
import { type TIApplicationStore, getApplicationStorage } from '../../../shared/services/localStore'

import { type BootProps } from './Boot'

const telemed = new TelemedService()

interface IInitialData {
    applicationStore: TIApplicationStore
    config: Config
}

interface IAccountsData {
    authService: AuthService
}

function loadInitialData(telemed: Telemed) {
    return forkJoin({
        applicationStore: getApplicationStorage(),
        config: getConfig()
    })
}

function loadAccounts(initial: IInitialData) {
    telemed.isIntegration = initial.config.isIntegration
    return initial.applicationStore.getAll('accounts').pipe(
        map((accounts) => new AuthService(telemed, initial.applicationStore, accounts, accounts[0] ?? null)),
        map((authService) => ({ ...initial, authService }))
    )
}

function prepareConfiguration(initial: IInitialData & IAccountsData): BootProps {
    const { applicationStore, authService, config } = initial
    const configStore = new ConfigStore(config, authService)

    return {
        applicationStore,
        telemed,
        authService,
        configStore
    }
}

function bootPropsResolver(telemed: Telemed): Observable<BootProps> {
    return loadInitialData(telemed).pipe(mergeMap(loadAccounts), map(prepareConfiguration))
}

export default asyncResolver(async () => await import('./Boot'), bootPropsResolver(telemed))
