import { type ObservableInput, fromEvent, throwError } from 'rxjs'
import { type AjaxConfig, ajax } from 'rxjs/ajax'
import { catchError, mergeMap, take, tap } from 'rxjs/operators'

import { GlobalState } from '../../app/types/global'

export function fetch$(request: AjaxConfig) {
    return ajax(request).pipe(catchOffline())
}

function catchOffline<T, O extends ObservableInput<any>>() {
    return catchError<T, O>((e, $) => {
        if (!navigator.onLine) {
            GlobalState.waitingInternetConnection.enable()
            return fromEvent(window, 'online').pipe(
                take(1),
                tap(GlobalState.waitingInternetConnection.disable),
                mergeMap(() => $)
            ) as unknown as O
        } else {
            return throwError(e) as unknown as O
        }
    })
}
