import { type CSSProperties, type ComponentType } from 'react'
import { asyncResolver as _asyncResolver, asyncResolverToLazy } from 'react-router-dom-asynchronously'
import { type Observable, fromEvent, throwError } from 'rxjs'
import { catchError, mergeMap, take, tap } from 'rxjs/operators'

import { GlobalState } from '../../app/types/global'

export interface AsyncComponentProps {
    style?: CSSProperties
}

export function asyncResolver(
    factory: () => Promise<{ default: ComponentType<AsyncComponentProps> }>
): Observable<ComponentType>
export function asyncResolver<TProps>(
    factory: () => Promise<{ default: ComponentType<TProps> }>,
    dataSource: Observable<TProps>
): Observable<ComponentType>

export function asyncResolver<TProps>(
    factory: () => Promise<{ default: ComponentType<TProps> }>,
    dataSource?: Observable<TProps>
): Observable<ComponentType> {
    return _asyncResolver(factory, dataSource as Observable<TProps>).pipe(
        catchError((e, $) => {
            if (!navigator.onLine) {
                GlobalState.waitingInternetConnection.enable()
                return fromEvent(window, 'online').pipe(
                    take(1),
                    tap(GlobalState.waitingInternetConnection.disable),
                    mergeMap(() => $)
                )
            } else {
                return throwError(e)
            }
        })
    )
}

export { asyncResolverToLazy }
