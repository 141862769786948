import buildQuery from 'odata-query'
import { type Observable } from 'rxjs'
import { type AjaxResponse } from 'rxjs/ajax'

import { type OrganizationModalReq } from '../../../entities/Organizations/OrganizationForm/OrganizationForm.store'
import { type AccountModel } from '../../models/Account/Account.model'
import { type INotifier } from '../../providers/notifications/notifications.context'
import { type AuthService } from '../../services'
import { type CommentEditResponse } from '../../ui/Measurements/models/MeasurementComments.model'
import { type ICalculateNorm, type ITrackingInfoDto } from '../../ui/Measurements/models/Measurements.model'
import { type IAddUserResponse, type IUserDto, type IUserResponse } from '../../ui/User'

export interface LoginRequest {
    email: string
    password: string
}

export interface ResetPasswordRequest {
    email: string
}

export interface RestorePasswordRequest {
    code: string
    password: string
}

export interface ChangePasswordRequest {
    oldPassword: string
    newPassword: string
    confirmPassword: string
}

export interface OrganizationDto {
    id: number
    idExt: string
    email: string | null
    address: string | null
    created: string
    level: number
    license: string
    name: string
    parent: {
        address: string
        created: string
        email: string
        idExt: string
        license: string
        name: string
        phone: string
    }
    parentId: number | null
    phone: string | null
    children?: OrganizationDto[]
    status: string
}

export interface IMeasurementsTargetLevelDto {
    '@odata.context': string
    'targetLevel': string
}

export interface AddOrganizationDto {
    idExt: string | null
    email: string | null
    address: string | null
    name: string
    parentId: number | null
    phone: string | null
    status: string
}

export interface OrganizationsResponse {
    '@odata.count': number
    'value': OrganizationDto[]
}

export interface IntegrationTokenResponse {
    token: string
    id: number
    doctorId: string
}

export interface IntegrationTokenTempResponse {
    '@odata.context': string
    'token': string
}

export interface editOrganizationsResponse extends OrganizationDto {
    '@odata.context': string
}

export interface DiagnosisDto {
    id: number
    code: string
    description: string
}

export interface NotificationsDto {
    id: number
    created: string
    isRead: boolean
    message: string
    recipientId: number
    title: string
}

export interface EditDiagnosisDto {
    code: string | null
    description: string | null
}

export interface DiagnosisResponse {
    '@odata.count': number
    'value': DiagnosisDto[]
}

export interface NotificationsResponse {
    '@odata.count': number
    'value': NotificationsDto[]
}

export interface editDiagnosisResponse extends DiagnosisDto {
    '@odata.context': string
}

export interface UserAllMeasurementsDto {
    firstName: string
    middleName: string
    lastName: string
    username: string
    sex: string
    birthDate: string
    orgId: number
}

export interface IMeasurementDto {
    id: number
    created: string
    userId: number
    type: string
    source: string
    value: string
    parameters: ParametersDto[]
    needInspection: boolean
    commentsNumber: number
    norm: string
    trackingInfo: ITrackingInfoDto[]
    user: UserAllMeasurementsDto
    skipDef?: boolean
    skipDias?: boolean
    calculateNorm?: ICalculateNorm
}

export interface MeasurementsResponse {
    '@odata.count': number
    'value': IMeasurementDto[]
}

export interface CommentDto {
    id: number
    created: string
    value: string
    user: {
        id: number
        orgId: number
    }
    measurementId: number
    updated: any
}

export interface CommentResponse {
    '@odata.context': string
    '@odata.count': number
    'value': CommentDto[]
}

export interface FilterRequests {
    orderBy?: string[]
    top?: number
    skip?: number
    filter?: any
    search?: any
}

export interface EditUserRequest {
    email?: string | null
    firstName?: string | null
    lastName?: string | null
    middleName?: string | null
    birthDate?: string | null
    sex?: string | null
    height?: number | null
    weight?: number | null
    phone?: string | null
    orgId?: number | null
    role?: string | null
    username?: string
}

export interface ParametersDto {
    type: string
    value: string
}

export interface UrgentInspectionResponse {
    id: number
    commentsNumber: number
    created: string
    userId: number
    type: string
    source: string
    value: string
    needInspection: boolean
    norm: number
    parameters: ParametersDto[]
    user: UserAllMeasurementsDto
}

export interface LimitsDto {
    min: number
    max: number
    measurementType: string
    userId?: number
    initiatorId?: number
}

export interface GetUserLimits {
    value: LimitsDto[]
}

export interface GetSubscribersDto {
    id: number
    created: string
    publisherId: number
    subscriberId: number
    eventType: string
}

export interface GetSubscribersResponse {
    value: GetSubscribersDto[]
}

export interface ResponseParametersResponse {
    value: ParametersDto[]
}

export interface RequestIntegrationLogin {
    doctorId: string
    organizationId: string
}

export interface IInspection {
    id: string
    externalId: string
    patientId: string
    doctorId: string
    organizationId: string
    organizationName: string
    deviceId: string
    deviceType: string
    status: string
    startDate: string
    endDate: string | null
}

interface IDeviceInspection {
    id: string
    type: number
}

export interface IDoctorInspection {
    id: string
    firstName: string
    lastName: string
    middleName: string
}

export interface IPatientInspection {
    id: string
    firstName: string | null
    lastName: string | null
    middleName: string | null
    birthDate: string | null
    diagnosis: string | null
    height: number | null
    phone: string | null
    sex: string | null
    weight: number | null
    anamnesis: string | null
}

export interface IOrganizationInspection {
    id: string
    email: string | null
    name: string | null
    phone: string | null
}

export interface IIndividualMeasurementLevels {
    maxValue: number
    minValue: number
    measurementType: string
    standardValue: number
}

export interface IInspectionDetail {
    id: string
    device: IDeviceInspection
    doctor: IDoctorInspection
    patient: IPatientInspection
    organization: IOrganizationInspection
    individualMeasurementLevels: IIndividualMeasurementLevels[]
    requiredMeasurementTimes: null
    startDate: string
    comment: string | null
    endDate: string
}

export interface IInspectionsOData {
    '@odata.context': string
    'value': IInspection[]
}

export abstract class Telemed<O = unknown> {
    abstract setUserContext(context: AccountModel | null): void

    public context: AccountModel | null | undefined
    public isIntegration: boolean = false

    abstract updateParams(authService: AuthService, notifications: INotifier): void

    protected abstract request<T>(
        method: string,
        url: string,
        headers: Record<string, string | null>,
        options?: O,
        requestBody?: any
    ): Observable<T>

    login(data: LoginRequest) {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<AccountModel>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users/Login`,
            headers,
            undefined,
            data
        )
    }

    integrationTempLogin() {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<IntegrationTokenTempResponse>('GET', '/SingleUsingToken', headers, undefined)
    }

    integrationLogin(token: string, data: RequestIntegrationLogin) {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json', 'Key': token }
        return this.request<IntegrationTokenResponse>('POST', `/SingleUsingTokenLogin`, headers, undefined, data)
    }

    logout() {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<AccountModel>('POST', `${this.isIntegration ? '' : '/v4'}/Users/Logout`, headers, undefined)
    }

    sendRestoreCode(data: ResetPasswordRequest) {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<AccountModel>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users/SendRestoreCode`,
            headers,
            undefined,
            data
        )
    }

    sendRestorePassword(data: RestorePasswordRequest) {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<AccountModel>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users/RestorePassword`,
            headers,
            undefined,
            data
        )
    }

    sendChangePassword(data: ChangePasswordRequest) {
        const headers = { 'Accept': '*/*', 'Content-Type': 'application/json' }
        return this.request<AccountModel>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users/ChangePassword`,
            headers,
            undefined,
            data
        )
    }

    getOrganizations(data?: any): Observable<OrganizationsResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<OrganizationsResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Organizations${buildQuery({
                count,
                ...data
            })}`,
            headers
        )
    }

    getChildrenOrganizations(id: number, data?: any): Observable<OrganizationsResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<OrganizationsResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Organizations(${id})/GetChildren` + buildQuery({ count, ...data }),
            headers
        )
    }

    editOrganization(
        id: number,
        data: Omit<OrganizationModalReq, 'id'>,
        rqOptions?: O
    ): Observable<editOrganizationsResponse> {
        const headers = { Accept: '*/*' }
        return this.request<editOrganizationsResponse>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Organizations(${id})`,
            headers,
            rqOptions,
            data
        )
    }

    addOrganization(
        data: Omit<OrganizationModalReq & { status: string }, 'id'>,
        rqOptions?: O
    ): Observable<editOrganizationsResponse> {
        const headers = { Accept: '*/*' }
        return this.request<editOrganizationsResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Organizations`,
            headers,
            rqOptions,
            data
        )
    }

    getDiagnosis(data?: any): Observable<DiagnosisResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<DiagnosisResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Diagnoses${buildQuery({
                count,
                ...data
            })}`,
            headers
        )
    }

    getNotifications(id: number | undefined, data?: any): Observable<NotificationsResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<NotificationsResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Notifications` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    editNotifications(id: number | undefined): Observable<NotificationsDto[]> {
        const headers = { Accept: '*/*' }
        return this.request<NotificationsDto[]>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Notifications/MarkAllAsRead`,
            headers
        )
    }

    deleteNotifications(id: number | undefined): Observable<NotificationsDto[]> {
        const headers = { Accept: '*/*' }
        return this.request<NotificationsDto[]>(
            'DELETE',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Notifications`,
            headers
        )
    }

    editDiagnosis(id: number, data?: EditDiagnosisDto, rqOptions?: O): Observable<editDiagnosisResponse> {
        const headers = { Accept: '*/*' }
        return this.request<editDiagnosisResponse>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Diagnoses(${id})`,
            headers,
            rqOptions,
            data
        )
    }

    addDiagnosis(data: EditDiagnosisDto, rqOptions?: O): Observable<editDiagnosisResponse> {
        const headers = { Accept: '*/*' }
        return this.request<editDiagnosisResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Diagnoses`,
            headers,
            rqOptions,
            data
        )
    }

    deleteDiagnosis(id: number, rqOptions?: O): Observable<editDiagnosisResponse> {
        const headers = { Accept: '*/*' }
        return this.request<editDiagnosisResponse>(
            'DELETE',
            `${this.isIntegration ? '' : '/v4'}/Diagnoses(${id})`,
            headers,
            rqOptions
        )
    }

    getAllMeasurements(data?: any): Observable<MeasurementsResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<MeasurementsResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Measurements` +
                buildQuery({
                    count,
                    ...data
                }),
            headers
        )
    }

    addMeasurementsComment(id: number, value: string, rqOptions?: O): Observable<CommentEditResponse> {
        const headers = { Accept: '*/*' }
        return this.request<CommentEditResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments`,
            headers,
            rqOptions,
            { value }
        )
    }

    deleteMeasurementsComment(id: number, idComment: number, rqOptions?: O): Observable<void> {
        const headers = { Accept: '*/*' }
        return this.request<void>(
            'DELETE',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments(${idComment})`,
            headers,
            rqOptions
        )
    }

    editMeasurementsComment(id: number, idComment: number, data?: any, rqOptions?: O): Observable<CommentEditResponse> {
        const headers = { Accept: '*/*' }
        return this.request<CommentEditResponse>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments(${idComment})`,
            headers,
            rqOptions,
            data
        )
    }

    getAllMeasurementsComments(id: number, data?: any): Observable<CommentResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        const orderBy = ['id desc']
        return this.request<CommentResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${id})/Comments` +
                buildQuery({
                    count,
                    orderBy,
                    ...data
                }),
            headers
        )
    }

    getStatistics(data?: any, rqOptions?: O): Observable<Blob> {
        const headers = { Accept: '*/*' }
        return this.request(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Reports/GetStatisticsReportShort(dateFrom=${data.dateFrom},dateTo=${
                data.dateTo
            })`,
            headers
        )
    }

    getStatisticsWithDecoding(data?: any): Observable<AjaxResponse<Blob>> {
        const headers = { Accept: '*/*' }
        return this.request(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Reports/GetStatisticsReportFull` +
                buildQuery({
                    ...data
                }),
            headers
        )
    }

    getUsers(data?: any): Observable<IUserResponse> {
        const headers = { Accept: '*/*' }
        const count = true
        return this.request<IUserResponse>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users${buildQuery({
                count,
                ...data
            })}`,
            headers
        )
    }

    editUser(id: number, data?: EditUserRequest, rqOptions?: O): Observable<IUserDto> {
        const headers = { Accept: '*/*' }
        return this.request<IUserDto>(
            'PATCH',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})`,
            headers,
            rqOptions,
            data
        )
    }

    addUser(data?: EditUserRequest, rqOptions?: O): Observable<IAddUserResponse> {
        const headers = { Accept: '*/*' }
        return this.request<IAddUserResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users/Register`,
            headers,
            rqOptions,
            data
        )
    }

    getUser(id: string, rqOptions?: O): Observable<IUserDto> {
        const headers = { Accept: '*/*' }
        return this.request<IUserDto>('GET', `${this.isIntegration ? '' : '/v4'}/Users(${id})`, headers, rqOptions)
    }

    setUrgentInspection(measurementId: number): Observable<UrgentInspectionResponse> {
        const headers = { Accept: '*/*' }
        return this.request<UrgentInspectionResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${measurementId})/SetUrgentInspection`,
            headers
        )
    }

    unsetUrgentInspection(measurementId: number): Observable<UrgentInspectionResponse> {
        const headers = { Accept: '*/*' }
        return this.request<UrgentInspectionResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Measurements(${measurementId})/UnsetUrgentInspection`,
            headers
        )
    }

    getLimits(rqOptions?: O): Observable<GetUserLimits> {
        const headers = { Accept: '*/*' }
        return this.request<GetUserLimits>('GET', `${this.isIntegration ? '' : '/v4'}/Limits`, headers, rqOptions)
    }

    getEcg(id: string, stab: boolean, g35: boolean, g50: boolean, g75: boolean, rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'GET',
            `${
                this.isIntegration ? '' : '/v4'
            }/Measurements(${id})/GetEcg(comp=${stab},hp1=true,bs50=${g50},lp35=${g35},lp75=${g75})`,
            headers,
            rqOptions
        )
    }

    getUserLimits(id: number, rqOptions?: O): Observable<GetUserLimits | LimitsDto[]> {
        const headers = { Accept: '*/*' }
        return this.request<GetUserLimits | LimitsDto[]>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Limits`,
            headers,
            rqOptions
        )
    }

    getUserInspections(data: any, rqOptions?: O): Observable<IInspectionsOData> {
        const headers = { Accept: '*/*' }
        return this.request<IInspectionsOData>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Inspections${buildQuery({
                ...data
            })}`,
            headers,
            rqOptions
        )
    }

    getUserInspection(id: string, rqOptions?: O): Observable<IInspectionDetail> {
        const headers = { Accept: '*/*' }
        return this.request<IInspectionDetail>(
            'GET',
            `${this.isIntegration ? '' : '/v4'}/Inspections('${id}')`,
            headers,
            rqOptions
        )
    }

    addCommentInspection(id: string, data: { comment: string }, rqOptions?: O): Observable<IInspectionDetail> {
        const headers = { Accept: '*/*' }
        return this.request<IInspectionDetail>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Inspections('${id}')/AddComment`,
            headers,
            rqOptions,
            data
        )
    }

    getMeasurementsTargetLevel(
        patientId: number,
        type: string,
        rqOptions?: O
    ): Observable<IMeasurementsTargetLevelDto> {
        const headers = { Accept: '*/*' }
        return this.request<IMeasurementsTargetLevelDto>(
            'GET',
            `/Measurements/GetTargetLevel(patientId=${patientId},type='${type}')`,
            headers,
            rqOptions
        )
    }

    setUserLimits(id: number, data: LimitsDto[], rqOptions?: O): Observable<GetUserLimits> {
        const headers = { Accept: '*/*' }
        return this.request<GetUserLimits>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Limits`,
            headers,
            rqOptions,
            data
        )
    }

    setRefLimits(data: LimitsDto[], rqOptions?: O): Observable<GetUserLimits> {
        const headers = { Accept: '*/*' }
        return this.request<GetUserLimits>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Limits`,
            headers,
            rqOptions,
            data
        )
    }

    userVisit(id: string, rqOptions?: O): Observable<GetUserLimits> {
        const headers = { Accept: '*/*' }
        return this.request<GetUserLimits>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${id})/Visit`,
            headers,
            rqOptions
        )
    }

    getSubscribers(subscriberId: number, publisherId: number, rqOptions?: O): Observable<GetSubscribersResponse> {
        const headers = { Accept: '*/*' }
        return this.request<GetSubscribersResponse>(
            'GET',
            `${
                this.isIntegration ? '' : '/v4'
            }/Users(${subscriberId})/Subscriptions/GetByPublisher(publisherId=${publisherId})`,
            headers,
            rqOptions
        )
    }

    setSubscribers(
        subscriberId: number,
        publisherId: number,
        data: { eventType: string }[],
        rqOptions?: O
    ): Observable<GetSubscribersResponse> {
        const headers = { Accept: '*/*' }
        return this.request<GetSubscribersResponse>(
            'POST',
            `${
                this.isIntegration ? '' : '/v4'
            }/Users(${subscriberId})/Subscriptions/Subscribe(publisherId=${publisherId})`,
            headers,
            rqOptions,
            data
        )
    }

    setParameters(userId: number, data: ParametersDto[], rqOptions?: O): Observable<ResponseParametersResponse> {
        const headers = { Accept: '*/*' }
        return this.request<ResponseParametersResponse>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Parameters`,
            headers,
            rqOptions,
            data
        )
    }

    unSubscribers(
        subscriberId: number,
        publisherId: number,
        data: { eventType: string }[],
        rqOptions?: O
    ): Observable<GetSubscribersResponse> {
        const headers = { Accept: '*/*' }
        return this.request<GetSubscribersResponse>(
            'POST',
            `${
                this.isIntegration ? '' : '/v4'
            }/Users(${subscriberId})/Subscriptions/Unsubscribe(publisherId=${publisherId})`,
            headers,
            rqOptions,
            data
        )
    }

    setUserDiagnosis(userId: number, data: { code: string }[], rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'POST',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Diagnoses`,
            headers,
            rqOptions,
            data
        )
    }

    unSetUserDiagnosis(userId: number, data: { code: string }[], rqOptions?: O): Observable<any> {
        const headers = { Accept: '*/*' }
        return this.request<any>(
            'DELETE',
            `${this.isIntegration ? '' : '/v4'}/Users(${userId})/Diagnoses`,
            headers,
            rqOptions,
            data
        )
    }
}
