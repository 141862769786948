import { type AccountModel } from '../../models/Account/Account.model'

import { type ILocalStore, openStore } from './adapter'

export interface IApplicationStoredModel {
    accounts: AccountModel[]
}

const OBJECT_NAME = 'hub3:application'
const VERSION = 1

function migrator(dbRequest: IDBOpenDBRequest, versions: IDBVersionChangeEvent) {
    switch (versions.oldVersion) {
        case 0:
            dbRequest.result.createObjectStore('accounts', { keyPath: 'userId' })
    }
}

export function getApplicationStorage() {
    return openStore<IApplicationStoredModel>(OBJECT_NAME, VERSION, migrator)
}

export type TIApplicationStore = ILocalStore<IApplicationStoredModel>
