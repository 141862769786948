import { makeObservable } from 'mobx'
import { Observable, of, throwError } from 'rxjs'
import { type AjaxConfig, type AjaxResponse } from 'rxjs/ajax'
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators'

import { Telemed } from '../api/telemed'
import { type AccountModel } from '../models/Account/Account.model'
import { type INotifier } from '../providers/notifications/notifications.context'
import { fetch$ } from '../utils'

import { type AuthService } from './authService'

export interface RequestOptions extends Partial<AjaxConfig> {
    authContext?: AccountModel
}

export class TelemedError extends Error {
    constructor(public readonly response: any) {
        super(response.error.message)
    }
}

interface TRequest {
    requestParams: AjaxConfig
    context: AccountModel | null
}

export class TelemedService extends Telemed<RequestOptions> {
    protected baseUrl = '/api'
    public context: AccountModel | null = null
    public authService: AuthService | null = null
    public notification: INotifier | null = null

    constructor() {
        super()

        makeObservable(this, {})
        this.makeRequest = this.makeRequest.bind(this)
    }

    updateParams(authService: AuthService, notification: INotifier) {
        this.authService = authService
        this.notification = notification
    }

    setUserContext(context: AccountModel | null) {
        this.context = context
    }

    protected request<T>(
        method: string,
        url: string,
        headers?: Record<string, string | null>,
        options?: RequestOptions,
        requestBody?: unknown
    ): Observable<T> {
        return new Observable<TRequest>((observer) => {
            if (!headers) {
                headers = {} as Record<string, string>
            }
            headers['Telemed-Client-Base'] = window.location.origin + process.env.PUBLIC_URL

            const context = options?.authContext || this.context

            if (context) {
                headers.Authorization = 'Bearer ' + context.accessToken
            }

            const requestParams = {
                method,
                headers,
                url: this.baseUrl + url,
                body: requestBody
            }

            observer.next({ context, requestParams })
            observer.complete()
        }).pipe(
            take(1),
            mergeMap((conf) => of(conf)),
            mergeMap(this.makeRequest),
            map((resp) => {
                if (resp.responseHeaders['content-type']?.includes('zip')) {
                    return resp
                }

                return resp.response
            })
        )
    }

    protected makeRequest({ context, requestParams }: TRequest): Observable<AjaxResponse<any>> {
        return fetch$(requestParams).pipe(
            tap((resp) => {
                if (![200, 201, undefined].includes(resp.status)) {
                    throw resp
                }
            }),
            catchError((err: AjaxResponse<any>) => {
                if (err.status === 401 && context !== null) {
                    if (this.authService) {
                        this.authService.logout(context, true)
                        this.notification?.warn('Сессия закончилась')
                    }
                }
                const _err = new TelemedError(err.response)
                return throwError(() => _err)
            })
        )
    }
}
