import { makeObservable, observable } from 'mobx'

import { type OrganizationDto } from '../../api/telemed'

export interface IAccountCompanyInfo {
    id: string
    name: string
}

export enum AccountType {
    SuperAdmin = 'superadmin',
    Admin = 'admin',
    AdminSupport = 'admin-support',
    Doctor = 'doctor',
    MedWorker = 'medworker',
    Patient = 'patient'
}

export class AccountModel {
    static fromObject(obj: AccountModel) {
        return makeObservable(Object.setPrototypeOf(obj, this), {
            userId: observable,
            role: observable,
            email: observable,
            phone: observable,
            firstName: observable,
            middleName: observable,
            lastName: observable,
            orgId: observable,
            organization: observable,
            avatar: observable,
            accessToken: observable,
            doctorId: observable
        })
    }

    private constructor(
        public readonly userId: number,
        public role: AccountType,
        public email: string,
        public phone: string,
        public firstName: string,
        public orgId: number | null,
        public organization: OrganizationDto | undefined,
        public middleName: string,
        public lastName: string,
        public avatar: string | null,
        public doctorId: string | null,
        public accessToken: string
    ) {}
}
