import { configure, when } from 'mobx'
import moment from 'moment/moment'
import { type JSXElementConstructor } from 'react'
import { createRoot } from 'react-dom/client'

import Boot from './app/providers/Boot/Boot.lazy'
import { GlobalState } from './app/types/global'

import { useOnMount } from './shared/hooks/useOnMount'
import { RootNodeContext } from './shared/hooks/useRootNode'

import { throwNull } from './shared/utils/typecheck'

import './index.css'

configure({ enforceActions: 'never' })

const container = throwNull(document.getElementById('root'), 'Root node not found')
const preloader = document.getElementById('preloader')
const root = createRoot(container)

moment.updateLocale('en', {
    months: [
        'Января',
        'Февраля',
        'Марта',
        'Апреля',
        'Мая',
        'Июня',
        'Июля',
        'Августа',
        'Сентября',
        'Октября',
        'Ноября',
        'Декабря'
    ]
})

function Application({ BaseComponent }: { BaseComponent: JSXElementConstructor<any> }) {
    useOnMount(() => {
        when(
            () => !GlobalState.waitingFirstState.value,
            () => {
                preloader?.remove()
                container.style.display = ''
            }
        )
    })

    return (
        <RootNodeContext.Provider value={container}>
            <BaseComponent />
        </RootNodeContext.Provider>
    )
}

Boot.subscribe((BaseComponent: JSXElementConstructor<any>) => {
    root.render(<Application BaseComponent={BaseComponent} />)
})
